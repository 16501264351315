import Thesaurus from './Thesaurus';
import {AfterViewInit} from '@angular/core';

/**
 *
 */
export class Node implements AfterViewInit {
  readonly slug: string

  constructor(protected thesaurus: Thesaurus) {}

  ngAfterViewInit(): void {}

  /**
   * this function is used to fire a custom event
   * @see https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
   * @param event
   * @param detail
   */
  fire(event: string, detail: {} = {}){
    window.dispatchEvent( new CustomEvent(event, {detail}) )
  }

  /**
   *
   * @param key
   */
  theo(key: string): string { return this.thesaurus[key] || `?!${key}!?` }
}

/**
 *
 */
export default class Section extends Node {
  protected buffer: string

  //remove the focus to ensure that the focus event handlers work properly
  protected blur(){
    (document.activeElement as HTMLInputElement).blur()
  }
}
