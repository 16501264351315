import {AfterViewInit, Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Node} from './core/nodes';
import {List, Map, Path, Size, Storage} from './core/interfaces';
import Thesaurus from './core/Thesaurus';

/**
 * @see https://comparator.sigma-lighting.com.srv05.24743.serviceprovider.de
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent extends Node {
  readonly slug = 'comparator'
  private _size: Size
  readonly sizes: Size[]
  private _col: string
  private _done: boolean
  private _error: string
  private _storage: Storage
  private _prefix = ''
  private _paths: Path[] = []
  private pathsClone: Path[] = [] //DO NOT REMOVE; it is used to prevent the page from being updated twice
  readonly pathsMin = 2
  private _map: Map = {}
  private _list: List = {}
  private _dirs: string[] = []


  constructor(protected thesaurus: Thesaurus, private http: HttpClient){
    super(thesaurus)

    //load cache: sizes
    this._size = localStorage.getItem(`${this.slug}_size`) as Size
    if(this._size === Size.medium) this._col = 'col-xl-4 col-lg-6'
    else if(this._size === Size.small) this._col = 'col-xl-3 col-lg-4 col-md-4'
    else{
      this._size = Size.big
      this._col = 'col-xl-6'
    }
    this.sizes = [Size.big, Size.medium, Size.small]

    //load cache: paths
    try{
      this._paths = JSON.parse( atob(localStorage.getItem(`${this.slug}_paths`)) )
      //console.log(this.paths)
    }catch(e){
      this._paths = [
        {directory: '', image: '', title: ''},
        {directory: '', image: '', title: ''}
      ]
    }
    this.pathsClone = [...this._paths] //@see https://www.freecodecamp.org/news/how-to-clone-an-array-in-javascript-1d3183468f6a/ clone

    this.http //load the repository & the names
      .get('server/data.php')
      .subscribe(
        (response: any) => {
          this._done = true;
          //console.log(response)
          if('error' in response){
            const error = response.error.trim();
            this._error = error.includes(' ') ? error :this.theo('error_'+ error);
          }else{
            this._storage = response.names
            this._prefix = response.prefix
            this._map = response.map
            this._list = response.list
            this._dirs = Object.keys(this._map)
          }
        },
        error => {
          this._done = true;
          this._error = error;
        },
        () => console.log(this._prefix, 'DONE!!!')
      )
  }

  get paths(): Path[] { return this._paths }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    /**
     * this event handler is used to add a box
     */
    window.addEventListener('add', () => {
      this._paths.push({directory: '', image: '', title: ''}) //add empty box
      this.pathsClone.push({directory: '', image: '', title: ''})
      this.storePaths()
    })

    /**
     * this event handler is used to remove the last box
     */
    window.addEventListener('remove', () => {
      if(this._paths.length <= this.pathsMin) return
      this._paths.pop() //remove last box
      this.pathsClone.pop()
      this.storePaths()
    })

    /**
     * this event handler is used to resize the boxes
     * @param size
     */
    window.addEventListener('resize', (event: CustomEvent) => {
      let size = event.detail.size as Size

      if(size === Size.medium) this._col = 'col-xl-4 col-lg-6'
      else if(size === Size.small) this._col = 'col-xl-3 col-lg-4 col-md-4'
      else{
        size = Size.big
        this._col = 'col-xl-6'
      }

      this._size = size
      this.storeSize()
    })

    /**
     * save
     */
    window.addEventListener('load', (event: CustomEvent) => {
      const data = this._storage[event.detail.name]
      if(data){
        this._size = data.size
        this._paths = data.paths
        this.pathsClone = data.paths

        this.storeSize()
        this.storePaths()
      }
    })

    /**
     *
     */
    window.addEventListener('delete', (event: CustomEvent) => {
      const name = event.detail.name
      this.http //load the repository & the names
        .post(
          'server/data.php',
          {action: 'delete', name}
        )
        .subscribe(
          (response: any) => {
            if('error' in response){
              const error = response.error.trim();
              alert(error.includes(' ') ? error :this.theo('error_post_'+ error))
            }else if(response.success){
              delete this._storage[name] //update the storage in the admin component
              alert(`'${name}' `+ this.theo('success_delete'))
              location.reload()
            }else alert('error_post_unknown')
          },
          error => alert(error)
        )
    })

    /**
     * save
     */
    window.addEventListener('save', (event: CustomEvent) => {
      const
        name = event.detail.name,
        data = {
          size: this._size,
          paths: this.pathsClone,
          last_modified: new Date()
        }
      this.http //load the repository & the names
        .post(
          'server/data.php',
          {action: 'save', name, data}
        )
        .subscribe(
          (response: any) => {
            if('error' in response){
              const error = response.error.trim();
              alert(error.includes(' ') ? error :this.theo('error_post_'+ error))
            }else if(response.success){
              this._storage[name] = data //update the storage in the admin component
              alert(`'${name}' `+ this.theo('success_save'))
              location.reload()
            }else alert('error_post_unknown')
          },
          error => alert(error)
        )
    })

    /**
     * path select
     */
    window.addEventListener('path_selected', (event: CustomEvent) => {
      const i = event.detail.index
      if(this._paths.length > i){
        delete event.detail.index
        this.pathsClone[i] = event.detail
        this.storePaths()
      }
    })
  }

  //getters
  get done(): boolean { return this._done }
  get error(): string|undefined { return this._error }
  get size(): Size { return this._size }
  get col(): string { return this._col }
  get storage(): Storage { return this._storage }
  get prefix(): string { return this._prefix }
  get map(): Map { return this._map }
  get list(): List { return this._list }
  get dirs(): string[] { return this._dirs }


  private storeSize(){
    localStorage.setItem(`${this.slug}_size`, this._size)
  }

  private storePaths(paths: Path[] = this.pathsClone){
    localStorage.setItem(
      `${this.slug}_paths`,
      btoa(JSON.stringify(paths)) //base-64 encode
    )
  }
}
