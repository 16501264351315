<div class="{{slug}} card">
  <div class="card-body row">
    <input
      type="text" class="form-control col-md-6"
      [attr.list]="'directories_'+ index" placeholder="{{theo('directory')}}"
      [(ngModel)]="directory" (ngModelChange)="updateDirectory()" (focusin)="focusDirectoryIn()" (focusout)="focusDirectoryOut()"
      value="{{directory}}" title="{{directory}}"
    >
    <input
      type="text"
      class="form-control col-md-5"
      [attr.list]="'images_'+ index" placeholder="{{theo('image')}}"
      [(ngModel)]="image" (ngModelChange)="updateImage()" (focusin)="focusImageIn()" (focusout)="focusImageOut()"
      value="{{image}}" title="{{image}}"
    >
    <button
      type="button" class="form-control btn btn-outline-danger col-md-1 button-close"
      title="{{theo('close')}}"
      (click)="close()"
    >&times;</button>
  </div>
  <img src="{{src}}" class="card-img-top" alt="{{title}}" title="{{title}}">

  <datalist id="directories_{{index}}">
    <option *ngFor="let dir of dirs" value="{{dir}}" title="{{dir}}">
  </datalist>
  <datalist id="images_{{index}}">
    <option *ngFor="let image of images" value="{{image}}" title="{{image}}">
  </datalist>
</div>
