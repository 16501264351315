/**
 *
 */
export default class Thesaurus {
  readonly box = 'Kachel'
  readonly box_add = 'Kachel hinzufügen'
  readonly box_remove = 'Kachel entfernen'
  readonly close = 'Schließen'
  readonly directory = 'Verzeichnis ...'
  readonly error_access_denied = `Sie haben keine Zugriffsrechte auf diese Anwendung.`
  readonly error_resources_not_found = `Die Ressourcen können nicht gefunden werden.`
  readonly error_post_empty = `Der name und die Pfade müssen angegeben werden.`
  readonly error_post_file = `Die Datenbank könnte nicht aktualisiert werden.\nVersuchen Sie es später nochmal.`
  readonly error_post_unknown = `Unbekannter Fehler. Kontaktieren Sie den Admin.\nE-Mail Adresse: patrick.meppe@sigmasport.com`
  readonly image = 'Aufnahme ...'
  readonly name = 'Vergleichsname ...'
  readonly name_clear = 'Vergleichsname entfernen'
  readonly name_delete = 'Löschen'
  readonly name_delete_title = 'Diese Aktion kann nicht rückgängig gemacht werden.'
  readonly name_delete_confirm = 'Sind Sie sicher?\n'+ this.name_delete_title
  readonly name_select = 'Vergleich auswählen'
  readonly name_title = 'Geben Sie den Namen ein, der für einen späteren Aufruf verwendet werden soll.'
  readonly name_title_insert = 'Dieser Name kann ohne Bedenken verwendet werden.'
  readonly name_title_update = 'Dieser Name wurde bereits angelegt.\nBeim Spiechern werden Sie also den alten Vergleich überschreiben.'
  readonly reload = 'Neu laden'
  readonly save = 'Speichern'
  readonly save_title = 'Vergleich speichern'
  readonly size_big = 'Dimension: Groß'
  readonly size_medium = 'Dimension: Mittel'
  readonly size_small = 'Dimension: Klein'
  readonly success_delete = 'wurde erfolgreich gelöscht.'
  readonly success_save = 'wurde erfolgreich gespeichert.'
}
