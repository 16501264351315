/**
 * this script is used to create the navigation's logic
 * @since PM (xx.xx.2020)
 * @see https://via.placeholder.com/500
 * @see https://www.w3schools.com/tags/tag_datalist.asp datalist
 * @see https://stackoverflow.com/questions/53058565/angular-and-datalist datalist & ngModel
 */
import {Component, Input, ViewEncapsulation} from '@angular/core';
import Section from '../nodes';
import Thesaurus from '../Thesaurus';
import {Size, Path, Storage} from '../interfaces';

@Component({
  selector: 'component-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class AdministrationComponent extends Section {
  readonly slug = 'navigation'

  @Input() paths: Path[]
  @Input() pathsMin: number
  @Input() sizes: Size[]
  @Input() size: Size
  @Input()
  set storage(storage: Storage){
    this._storage = storage
    this._names = Object.keys(storage)
    this.name = ''
    this.nameNew = ''
  }
  get storage(): Storage { return this._storage }


  private _storage: Storage
  private _names = [] as string[]
  name = ''
  nameNew = ''
  private _nameNewCN = '' //the class name
  private _nameNewTitle = 'name_title' //the title


  constructor(protected thesaurus: Thesaurus) {
    super(thesaurus);
  }

  get names(): string[] { return this._names  }
  get nameNewCN(): string { return this._nameNewCN  }
  get nameNewTitle(): string { return this._nameNewTitle  }

  namesIn(){
    this.buffer = this.name
    this.name = ''
  }

  namesOut(){
    if(!this.name){
      this.name = this.buffer
      this.buffer = null
    }
  }

  reload(){ location.reload() } //reload by proxy

  load(){
    if(this.name.length && this.name in this._storage) this.fire('load', {name: this.name})
    this.blur()
  }

  validate(){
    const name = this.nameNew.trim()
    if(name in this._storage){ //update
      this._nameNewCN = 'is-invalid'
      this._nameNewTitle = 'name_title_update'
    }else if(name.length){ //insert
      this._nameNewCN = 'is-valid'
      this._nameNewTitle = 'name_title_insert'
    }else{ //empty
      this._nameNewCN = ''
      this._nameNewTitle = 'name_title'
    }
  }

  delete(){
    const name = this.name.trim()
    if( name.length && confirm(this.theo('name_delete_confirm')) ) this.fire('delete', {name})
  }

  save(){
    const name = this.nameNew.trim()
    if(name.length) this.fire('save', {name})
  }
}
