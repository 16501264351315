/**
 * this script is used to create the box logic
 * @since PM (xx.xx.2020)
 * @see https://via.placeholder.com/500
 * @see https://www.w3schools.com/tags/tag_datalist.asp datalist
 * @see https://stackoverflow.com/questions/53058565/angular-and-datalist datalist & ngModel
 */
import {Component, Input, ViewEncapsulation} from '@angular/core';
import Section from '../nodes';
import {List, Map, Path, Strimber} from '../interfaces';
import Thesaurus from '../Thesaurus';

@Component({
  selector: 'component-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class BoxComponent extends Section {
  readonly slug = 'box'
  readonly placeholder = 'assets/images/placeholder.png'

  @Input() index: number
  @Input() path: Path
  @Input() prefix: string
  @Input() dirs: string[]
  @Input() map: Map
  @Input() set list(list: List){
    if(list[this.path.title]){
      this.images = this.map[this.path.directory]
      this.directory = this.path.directory
      this.image = this.path.image
      this.title = this.path.title
      this._src = `${this.prefix}/${this.path.title}`
    }else this.clearImage()
  }

  images: string[] = []
  directory = ''
  image = ''
  title = ''
  private _src: string


  constructor(protected thesaurus: Thesaurus) {
    super(thesaurus);
  }

  get src(): string { return this._src }

  updateDirectory(){
    this.image = ''
    this.images = this.map[this.directory] || []
    this.clearImage()
    this.storePath()
    this.blur()
  }

  focusDirectoryIn(){
    this.buffer = this.directory
    this.directory = ''
  }

  focusDirectoryOut(){
    if(!this.directory){
      this.directory = this.buffer
      this.buffer = null
    }
  }

  updateImage(){
    if(this.image){
      this.title = `${this.directory}/${this.image}`
      this._src = `${this.prefix}/${this.title}`
    }else this.clearImage()
    this.storePath();
    (document.activeElement as HTMLInputElement).blur() //remove the focus to ensure that the focus event handlers work properly
  }

  focusImageIn(){
    this.buffer = this.image
    this.image = ''
  }

  focusImageOut(){
    if(!this.image){
      this.image = this.buffer
      this.buffer = null
    }
  }

  close(){
    this.directory = ''
    this.image = ''
    this.images = []
    this.clearImage()
    this.storePath()
  }

  /**
   * this function is used to clear the image
   * @private
   */
  private clearImage(){
    this.title = ''
    this._src = this.placeholder
  }

  /**
   * this function is used to store the new path by proxy
   * @private
   */
  private storePath(){
    this.fire(
      'path_selected',
      {
        index: this.index,
        directory: this.directory,
        image: this.image,
        title: this.title
      }
    )
  }
}
