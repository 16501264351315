<main *ngIf="done; else loading" class="container-fluid">
  <section *ngIf="error; else comparator" class="error">
    <img src="assets/images/logo-large.png" alt="logo">
    <div class="mt-4" [innerText]="error"></div>
  </section>
  <ng-template #comparator>
    <component-administration
      [paths]="paths"
      [pathsMin]="pathsMin"
      [sizes]="sizes"
      [size]="size"
      [storage]="storage"
    ></component-administration>
    <article class="row">
      <section *ngFor="let path of paths; let i = index" class="{{col}}">
        <component-box
          [index]="i" [path]="path" [prefix]="prefix"
          [dirs]="dirs" [map]="map" [list]="list"
        ></component-box>
      </section>
    </article>
  </ng-template>
</main>
<ng-template #loading>
  <aside class="loader center"></aside>
</ng-template>
