/**
 * @since PM (04.08.2020)
 */

export type Strimber = string|number

export type Path = {directory: string, image: string, title: string}

/**
 * the different box sizes
 */
export enum Size {
  big = 'big',
  medium = 'medium',
  small = 'small'
}

//data
type Data<Type> = {[key: string]: Type}
export type Map = Data<string[]>
export type List = Data<boolean>
export type Storage = Data<{size: Size, paths: Path[]}>

