import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import Thesaurus from './core/Thesaurus';
import {AdministrationComponent} from './core/administration/administration.component';
import {BoxComponent} from './core/box/box.component';

@NgModule({
  declarations: [
    AppComponent,
    AdministrationComponent,
    BoxComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [Thesaurus],
  bootstrap: [AppComponent]
})
export class AppModule { }
