<section class="administration mt-3 mb-4" role="toolbar" aria-label="Toolbar with button groups">
  <img src="assets/images/logo-large.png" alt="logo" class="logo mr-4">
  <div class="btn-group mr-4" role="group" aria-label="First group">
    <button
      type="button" class="btn btn-info"
      [innerText]="theo('reload')" (click)="reload()"
    ></button>
  </div>
  <div class="btn-group mr-4" role="group" aria-label="Second group">
    <button type="button" class="btn btn-primary" [innerText]="theo('box_add')" (click)="fire('add')"></button>
    <button
      type="button" class="btn btn-danger"
      [innerText]="theo('box_remove')" (click)="fire('remove')"
      [disabled]="paths.length <= pathsMin"
    ></button>
  </div>
  <div class="btn-group btn-group-toggle" [attr.data-toggle]="'buttons'" role="group" aria-label="Third group">
    <button
      *ngFor="let sizeI of sizes"
      type="button" class="btn btn-secondary {{sizeI == size ? 'active' : ''}}"
      [innerText]="theo('size_'+ sizeI)" (click)="fire('resize', {size: sizeI})"
    ></button>
  </div>
  <form class="mt-2">
    <div class="form-row">
      <div class="col input-group">
        <input
          type="text" class="form-control" name="name" autocomplete="off"
          placeholder="{{theo('name_select')}}"
          [(ngModel)]="name" (ngModelChange)="load()" [attr.list]="'names'"
          value="{{name}}" (focusin)="namesIn()" (focusout)="namesOut()"
        >
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-outline-dark"
            title="{{theo('name_clear')}}"
            [disabled]="name.length == 0"
            (click)="name = ''"
          >&times;</button>
          <button
            type="button"
            class="btn btn-outline-danger"
            title="{{theo('name_delete_title')}}"
            [disabled]="name.length == 0"
            (click)="delete()"
            [innerText]="theo('name_delete')"
          ></button>
        </div>
      </div>
      <div class="col input-group">
        <input
          type="text"
          class="form-control {{nameNewCN}}" name="name-new"
          placeholder="{{theo('name')}}" title="{{theo(nameNewTitle)}}"
          [(ngModel)]="nameNew" (ngModelChange)="validate()"
        >
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-outline-success"
            title="{{theo('save_title')}}"
            [disabled]="nameNew.length == 0"
            (click)="save()"
          >{{theo('save')}}</button>
        </div>
      </div>
    </div>
    <datalist id="names">
      <option *ngFor="let name of names" value="{{name}}" title="{{name}}">
    </datalist>
  </form>
</section>
